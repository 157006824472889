.cover {
    position: relative;
    min-height: 180px;
    width: 100%;
    padding-top: 2px;
    overflow: hidden;
}

.cover-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
}

.cover-bg.loaded {
    background-image: url('../../data/images/home.jpg');
    opacity: 1;
}

/* .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.33);
    z-index: 1;
}
 */
.cover-content {
    position: relative;
    z-index: 2;
}

.cover-text {
    margin: 0;
    color: white;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Futura', serif;
}

.cover-title {
    padding-top: 15px;
    color: white;
    font-weight: 400;
    align-items: center;
    text-align: center;
    font-family: 'Futura', serif;
}

.cover-description {
    padding: 0px 20px;
    color: inherit;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Futura', serif;
}