/* hotel card */

.hotel-card {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hotel-card img {
    object-fit: cover;
    height: 200px;
    border-radius: 8px 8px 0 0;
    width: 100%;
}

.hotel-card .description {
    padding: 12px;
    color: var(--secondary-text-color);
}

.hotel-card .footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.hotel-card .title {
    color: var(--primary-text-color);
    margin: 6px 0;
}

.hotel-card .address {
    margin: 8px 0 12px 0;
}

.hotel-card .address .location {
    color: var(--primary-color);
    padding-right: 4px;
}

.hotel-card .rating {
    display: flex;
    align-items: flex-end;
}

.hotel-card .rate {
    padding-bottom: 2px;
    padding-left: 4px;
}

.hotel-card .price-old {
    text-align: end;
    text-decoration: line-through;
    color: var(--primary-text-color);
}

.hotel-card .price-new {
    color: var(--primary-color);
    font-size: 24px;
}

.hotel-card .tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
}

.hotel-card .tags div {
    border: #51676C 1px solid;
    border-radius: 12px;
    margin: 2px;
    padding: 4px 8px;
    color: #51676C;
}

.hotel-card .tags div svg {
    color: var(--secondary-color);
    padding-right: 4px;
    font-size: 18px;
}

.hotel-card .divider {
    width: 100%;
    border: none;
    border-bottom: 2px solid var(--primary-color);
    margin: 12px 0;
}

.hotel-card:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
    transform: scale(1.05);
    cursor: pointer;
}
